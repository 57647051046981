<template>
    <div
        class="d-flex flex-column justify-content-center container p-lg-0"
        style="margin:3rem auto;"
    >
        <div class="d-flex border register" style="border-radius: 15px;">
            <bg-auth style="width: 65%;" class="bg-auth" />
            <div
                class="d-flex flex-column justify-content-between auth-form"
                style="width:35%;"
            >
                <auth-form header="Create Account" class="auth-form">
                    <div class="mb-2 mx-2">
                        <div class="input-group">
                            <span class="input-group-text input-icon">
                                <user-icon />
                            </span>
                            <input
                                type="text"
                                class="form-control"
                                v-model="auth.name"
                                placeholder="Name"
                                style="border-left-color:white"
                            />
                        </div>

                        <div
                            class="error invalid-feedback d-block"
                            v-if="
                                submitStatus == 'ERROR' &&
                                    !$v.auth.name.required
                            "
                        >
                            Name is required.
                        </div>
                    </div>

                    <div class="mb-2 mx-2">
                        <div class="input-group">
                            <span class="input-group-text input-icon">
                                <phone-icon />
                            </span>
                            <input
                                type="text"
                                class="form-control"
                                v-model="auth.phone"
                                placeholder="Phone"
                                style="border-left-color:white"
                            />
                        </div>

                        <div
                            class="error invalid-feedback d-block"
                            v-if="
                                submitStatus == 'ERROR' &&
                                    !$v.auth.phone.required
                            "
                        >
                            Phone is required.
                        </div>
                        <div
                            class="error invalid-feedback d-block"
                            v-if="submitStatus == 'ERROR' && authPhoneError"
                        >
                            {{ authPhoneError }}
                        </div>
                    </div>

                    <!-- <div class="mb-2 mx-2">
                        <input
                            type="text"
                            class="form-control"
                            v-model="auth.phone"
                        />
                        <div
                            class="error invalid-feedback d-block"
                            v-if="
                                submitStatus == 'ERROR' &&
                                    !$v.auth.phone.required
                            "
                        >
                            Phone is required.
                        </div>
                    </div> -->
                    <div class="mb-2 mx-2">
                        <div class="input-group">
                            <span class="input-group-text input-icon">
                                <key-icon class="key-icon" />
                            </span>
                            <input
                                :type="passwordType"
                                class="form-control"
                                v-model="auth.password"
                                placeholder="Set Password"
                                style="border-left-color:white"
                            />
                            <span
                                class="input-group-text"
                                id="basic-addon1"
                                @click="handleShowPwd"
                            >
                                <font-awesome-icon
                                    v-if="showPassword"
                                    icon="eye"
                                />
                                <font-awesome-icon v-else icon="eye-slash" />
                            </span>
                            <div
                                class="error invalid-feedback d-block"
                                v-if="
                                    submitStatus == 'ERROR' &&
                                        !$v.auth.password.required
                                "
                            >
                                Password is required.
                            </div>
                        </div>
                    </div>
                    <div class="mb-2 mx-2">
                        <div class="input-group">
                            <span class="input-group-text input-icon">
                                <key-icon class="key-icon" />
                            </span>
                            <input
                                :type="confirmPasswordType"
                                class="form-control"
                                v-model="auth.password_confirmation"
                                placeholder="Password Again"
                                style="border-left-color:white"
                            />
                            <span
                                class="input-group-text"
                                id="basic-addon1"
                                @click="handleShowConfirmPwd"
                            >
                                <font-awesome-icon
                                    v-if="showConfirmPassword"
                                    icon="eye"
                                />
                                <font-awesome-icon v-else icon="eye-slash" />
                            </span>
                            <div
                                class="error invalid-feedback d-block"
                                v-if="
                                    submitStatus == 'ERROR' &&
                                        !$v.auth.password_confirmation.required
                                "
                            >
                                Confirm Password is required.
                            </div>
                            <div
                                class="error invalid-feedback d-block"
                                v-if="
                                    submitStatus == 'ERROR' &&
                                        !$v.auth.password_confirmation
                                            .sameAsPassword
                                "
                            >
                                Confirm Password must be same as new password.
                            </div>
                        </div>
                    </div>
                    <div class="mx-2">
                        <div class="input-group">
                            <span class="input-group-text input-icon">
                                <mail-icon />
                            </span>
                            <input
                                type="text"
                                class="form-control"
                                v-model="auth.email"
                                placeholder="E-mail"
                                style="border-left-color:white;"
                            />
                        </div>
                        <div
                            class="error invalid-feedback d-block"
                            v-if="
                                submitStatus == 'ERROR' &&
                                    !$v.auth.email.required
                            "
                        >
                            Email is required.
                        </div>
                        <div
                            class="error invalid-feedback d-block"
                            v-if="
                                submitStatus == 'ERROR' && !$v.auth.email.email
                            "
                        >
                            Email must be email.
                        </div>
                    </div>
                    <!-- <div class="d-flex justify-content-end mx-2 mb-3">
                        <router-link class="text-dark" :to="{ name: 'Login' }"
                            >Create with phone number</router-link
                        >
                    </div> -->
                    <div
                        class="error invalid-feedback d-block mx-2"
                        v-if="submitStatus == 'ERROR' && authError"
                    >
                        {{ authError }}
                    </div>
                    <div class="text-center mx-2 mt-2">
                        <button
                            v-if="!isLoading"
                            @click="createUser"
                            class="w-100 auth-button"
                        >
                            <span style="font-size:1rem">Create Account</span>
                            <font-awesome-icon
                                class="ms-2"
                                :icon="['fas', 'chevron-right']"
                            />
                        </button>
                        <button
                            v-else
                            class="btn btn-primary loading-btn w-100"
                            type="button"
                            disabled
                        >
                            <span
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Loading...
                        </button>
                    </div>
                </auth-form>
                <div class="d-flex justify-content-end w-100 border-top py-2">
                    <router-link class="text-dark mx-2" :to="{ name: 'Login' }"
                        >Already have an account? Login</router-link
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BgAuth from '../../components/auth/BgAuth';
import AuthForm from '../../components/auth/AuthForm';
// import Button from '../../components/reusable/Button';

// mutation
import UserRegister from '../../graphql/mutations/auth/UserRegister.gql';

// vuelidate
import { validationMixin } from 'vuelidate';
import { required, email, sameAs } from 'vuelidate/lib/validators';

export default {
    mixins: [validationMixin],

    components: {
        'bg-auth': BgAuth,
        'auth-form': AuthForm,
        // 'auth-button': Button,
    },

    data() {
        return {
            auth: {
                name: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
            },
            passwordType: 'password',
            showPassword: false,
            confirmPasswordType: 'password',
            showConfirmPassword: false,
            submitStatus: null,
            isLoading: false,
            authError: '',
            authPhoneError: '',
        };
    },

    validations: {
        auth: {
            name: {
                required,
            },
            email: {
                required,
                email,
            },
            phone: {
                required,
            },
            password: {
                required,
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs('password'),
            },
        },
    },

    mounted() {
        const socialUser = this.$store.getters['auth/getSocialUser'];

        if (socialUser) {
            this.auth.name = socialUser.name;
            this.auth.email = socialUser.email;
            this.auth.phone = socialUser.phone;
        }
    },

    methods: {
        handleShowPwd() {
            this.showPassword = !this.showPassword;
            if (this.showPassword) {
                this.passwordType = 'string';
            } else {
                this.passwordType = 'password';
            }
        },
        handleShowConfirmPwd() {
            this.showConfirmPassword = !this.showConfirmPassword;
            if (this.showConfirmPassword) {
                this.confirmPasswordType = 'string';
            } else {
                this.confirmPasswordType = 'password';
            }
        },
        createUser() {
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR';
            } else {
                this.isLoading = true;

                this.$apollo
                    .mutate({
                        mutation: UserRegister,

                        variables: {
                            name: this.auth.name,
                            phone: this.auth.phone,
                            password: this.auth.password,
                            password_confirmation: this.auth
                                .password_confirmation,
                            email: this.auth.email,
                        },
                    })
                    .then(response => {
                        this.submitStatus = 'OK';
                        this.$store.commit(
                            'auth/ADD_TOKEN',
                            response.data.userRegister.tokens.access_token,
                        );
                        this.$store.commit(
                            'auth/ADD_REFRESH_TOKEN',
                            response.data.userRegister.tokens.refresh_token,
                        );
                        this.isLoading = false;
                        this.$router.push({
                            name: 'ShoppingBag',
                        });
                    })
                    .catch(errors => {
                        console.log(errors);
                        this.submitStatus = 'ERROR';
                        this.isLoading = false;

                        if (
                            errors.graphQLErrors[0].extensions.validation[
                                'input.email'
                            ]
                        ) {
                            this.authError =
                                'The email has already been taken.';
                        } else if (
                            errors.graphQLErrors[0].extensions.validation[
                                'input.phone'
                            ]
                        ) {
                            this.authPhoneError =
                                'Phone Number has already been taken.';
                        } else {
                            this.authError =
                                errors.graphQLErrors[0].extensions.reason;
                        }
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.auth-button {
    background-color: #49bb09;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    width: max-content;
    padding: 0.5rem 1rem;
    transition: all 0.5s ease;
    border: none;
    &:hover {
        background-color: var(--color-green);
        color: #fff;
    }
}
.auth-form {
    padding: 1rem 0;
    .key-icon {
        rotate: 180deg;
    }
    .input-icon {
        background: white;
        // border-right-color: 1px solid white;
        padding-right: 0;
        border: 1px solid #0000000d;
    }
}
.input-group-text {
    border-radius: 10px;
}
.form-control {
    border: 1px solid #0000000d;
    border-radius: 10px;
}
::placeholder {
    color: #111111;
    opacity: 0.5;
}
@media (min-width: 280px) and (max-width: 991.98px) {
    .register {
        flex-direction: column;
    }
    .bg-auth {
        width: 100% !important;
    }
    .auth-form {
        width: 100% !important;
    }
}
</style>
